<template>
  <ContentBlock>
    <div class="edit-role">
      <div class="edit-role__heading">
        <div class="subpage__title heading-3-sb grey-100">
          {{ pageTitle }}
        </div>
      </div>
      <div class="edit-role__input">
        <ValidationObserver ref="observer">
          <SlValidate
            v-slot="{ invalid }"
            :rules="idRules"
          >
            <SlInput
              v-model="roleName"
              :label="$t('Web.Users.Name')"
              :is-invalid="invalid"
              max-length="64"
              required
            />
          </SlValidate>
        </ValidationObserver>
      </div>
      <div class="edit-role__input">
        <SlTextArea
          v-model="roleDescription"
          height="80"
          :label="$t('Web.Users.Desc')"
          bottom-round
        />
      </div>

      <div class="edit-role__content">
        <div class="edit-role__heading">
          <h4 class="heading-4-sb">
            {{ $t('Web.Users.AccessSettings') }}
          </h4>
          <p class="body-1 grey-60">
            {{ $t('Web.Users.AccessSettingsSubtitle') }}
          </p>
        </div>

        <div class="edit-role__settings-list">
          <h5 class="heading-5-sb">
            {{ $t('Web.Users.RoleTabs') }}
          </h5>
          <template v-for="(row) in settingsObject">
            <div
              v-if="isRowVisible(row, roleSettingTypes.TAB_RESTRICTION)"
              :key="row.label"
              class="edit-role__row"
            >
              <span class="body-2-md">
                {{ row.label }}
              </span>
              <SlControl
                :key="row.value"
                v-model="row.value"
                :options="row.options"
              />
            </div>
          </template>
        </div>

        <hr>

        <div class="edit-role__settings-list">
          <h5 class="heading-5-sb">
            {{ $t('Web.Users.RoleFunc') }}
          </h5>
          <template v-for="(row) in settingsObject">
            <div
              v-if="isRowVisible(row, roleSettingTypes.FUNCTIONAL_RESTRICTION)"
              :key="row.label"
              class="edit-role__row"
            >
              <SlCheckbox
                v-model="row.value"
                :label="row.label"
              >
                <icon
                  v-if="row.info"
                  v-tooltip.bottom="getTooltip(row.info)"
                  data="@icons/info.svg"
                  class="fill-off size-20"
                />
              </SlCheckbox>
            </div>
          </template>
        </div>
      </div>

      <hr>

      <div class="edit-role__settings-list">
        <h5 class="heading-5-sb">
          {{ $t('Web.Users.RoleData') }}
        </h5>
        <template v-for="(row) in settingsObject">
          <div
            v-if="isRowVisible(row, roleSettingTypes.DATA_RESTRICTION)"
            :key="row.label"
            class="edit-role__row"
          >
            <SlCheckbox
              v-model="row.value"
              :label="row.label"
            >
              <icon
                v-if="row.info"
                v-tooltip.bottom="getTooltip({
                  content: row.info,
                  style: 'max-width: 250px'
                })"
                data="@icons/info.svg"
                class="fill-off size-20"
              />
            </SlCheckbox>
          </div>
        </template>
      </div>

      <div class="edit-role__footer">
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleBack"
        >
          {{ cancelButtonLabel }}
        </SlButton>
        <SlButton
          :disabled="!isEdited"
          @click="handleSave"
        >
          {{ saveButtonLabel }}
        </SlButton>
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { roleSettingTypes, defaultRoleSettings } from '@/config/users/users.config';
import cloneObject from '@/helpers/utils/cloneObject';
import { modal } from '@/mixins/modal';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';

export default {
  name: 'EditRole',
  mixins: [modal],
  data() {
    return {
      isEdited: false,
      isRedirect: false,
      name: null,
      description: null,
      role_name: null,
      role_description: null,
      roleSettingTypes,
      initialSettingsObject: defaultRoleSettings(this),
      settingsObject: defaultRoleSettings(this)
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.userAccess.roles || {}
    }),
    ...mapGetters({
      takenNamesByKey: 'userAccess/takenNamesByKey'
    }),
    takenNames() {
      return this.takenNamesByKey('roles');
    },
    isEditState() {
      return this.$sl_routeName === routeNames.EDIT_ROLE;
    },
    roleId() {
      return this.$route.params.roleId;
    },
    idRules() {
      return {
        required: true,
        'sl_not_includes': {
          values: this.takenNames.filter(name => {
            if (this.isEditState) {
              return name !== this.name;
            }

            return true;
          }),
          message: this.$t('Web.Error.AlreadyExist', {
            1: this.$t('Web.Error.Names.Role')
          })
        }
      };
    },
    roleName: {
      get() {
        return this.role_name ?? this.name;
      },
      set(value) {
        this.role_name = value;
      }
    },
    roleDescription: {
      get() {
        return this.role_description ?? this.description;
      },
      set(value) {
        this.role_description = value;
      }
    },
    areSettingsEdited() {
      const areSettingsEdited = !checkEqualObject(this.settingsObject, this.initialSettingsObject);
      const isNameEdited = this.roleName !== this.name;
      const isDescriptionEdited = this.roleDescription !== this.description;

      return areSettingsEdited || isNameEdited || isDescriptionEdited;
    },
    pageTitle() {
      return this.isEditState
        ? this.$t('EditInfo.EditRole')
        : this.$t('WiMultiuserTab.NewRole');
    },
    saveButtonLabel() {
      return this.isEditState
        ? this.$t('Web.Collections.SaveChanges')
        : this.$t('Web.Modals.BtnCreate');
    },
    cancelButtonLabel() {
      return this.isEditState
        ? this.$t('Web.Collections.Discard')
        : this.$t('Common.Cancel');
    }
  },
  watch: {
    areSettingsEdited(val) {
      this.isEdited = val;
    }
  },
  async mounted() {
    if (this.roleId && this.isEditState) {
      const { name, descr, settings } = await this.fetchRoleById({ id: this.roleId, settings: defaultRoleSettings(this) });

      this.settingsObject = cloneObject(settings);
      this.initialSettingsObject = cloneObject(settings);
      this.name = name;
      this.description = descr;

      return;
    }

    this.settingsObject = defaultRoleSettings(this);
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions('userAccess', [
      'createRole',
      'updateRole',
      'fetchRoleById'
    ]),
    getTooltip,
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      const modalData = {
        icon: 'style_save_double',
        title:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.TitleLeavePage')
              : this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.TextLeaveProject')
              : this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        confirmText:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.ButtonLeave')
              : this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: () => {
          this.isRedirect = true;

          next();
        }
      };

      this.showModal(modalsId.SL_CONFIRM_MODAL, modalData);
    },
    handleBack() {
      this.$router.push({
        name: routeNames.ROLES
      });
    },
    isRowVisible(row, type) {
      return row.type === type;
    },
    async handleSave() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      const payload = {
        settings: this.settingsObject,
        name: this.roleName,
        description: this.roleDescription
      };

      if (this.isEditState) {
        await this.updateRole({
          ...payload,
          id: this.roleId
        });
      } else {
        await this.createRole(payload);
      }

      this.isRedirect = true;

      this.handleBack();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/users/edit-role.scss";
</style>
